<template>
  <div
    v-if="show"
    class="flex items-center gap-1.5 rounded-sm bg-white p-1 md:p-1.5"
  >
    <span class="-mb-1 text-xs uppercase leading-none tracking-max md:text-sm">
      {{ $t(`product.availability.${availability}`) }}
    </span>
    <div
      class="size-1.5 rounded-full"
      :class="{
        'bg-orange': availability === ProductAvailability.ON_REQUEST,
        'bg-red': availability === ProductAvailability.SOLD_OUT,
      }"
    />
  </div>
</template>

<script setup lang="ts">
import type { Schemas } from '#shopware'
import { ProductAvailability } from '~/utils/types/shopware-custom'

const props = defineProps<{
  product: Schemas['Product']
}>()

const availability = computed(
  () =>
    props.product.translated?.customFields
      ?.product_detail_custom_fields_availability
)

const show = computed(() => {
  return (
    availability.value === ProductAvailability.ON_REQUEST ||
    availability.value === ProductAvailability.SOLD_OUT
  )
})
</script>
